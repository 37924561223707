// @tailwind base;
// @tailwind components;
// @tailwind utilities;

$app-font-path: "fonts";
@import "node_modules/@systemair/styles/src/styles/app-base";
@import "node_modules/@systemair/styles/src/styles/app-components";
@import "node_modules/@systemair/styles/src/styles/app-utilities";
// @import "node_modules/@systemair/styles/src/styles/components/btn";
// @import "node_modules/@systemair/styles/src/styles/components/forms";
// @import "node_modules/@systemair/styles/src/styles/components/select-btn";
// @import "node_modules/@systemair/styles/src/styles/components/stretched-link";
// @import "node_modules/@systemair/styles/src/styles/components/table";

input[type="number"].spin-button-none::-webkit-outer-spin-button,
input[type="number"].spin-button-none::-webkit-inner-spin-button,
input[type="number"].spin-button-none {
  appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

@media  (min-width: 768px /* Same as Systemair tailwind "sm-max:" class + 1 */) {
  .hide-on-desktop { display: none; }
}

@media  (max-width: 767px /* Same as Systemair tailwind "sm-max:" class */) {
  .hide-on-mobile { display: none; }
}

/* vertical-lr not supported by tailwind: https://github.com/tailwindlabs/tailwindcss/discussions/2494 */
.vertical-lr {
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
}

/* The texts needs to be slighly smaller then text-xs */
@media  (max-width: 767px /* Same as Systemair tailwind "sm-max:" class */) {
  .sm-max\:sound-cell-extra-small {
    font-size: 0.6rem;
  }
}

// Property selector elements
.property-selector-group-col-gap {  
  column-gap: 2.5em;
}
.property-selector-property {  
  flex-basis: calc(50% - 1.25em);
  @media (min-width: 768px /* Same as Systemair tailwind "sm-max:" class + 1 */)  {
    max-width: calc(50% - 1.25em);
  }
}

// Referred to in Figma but can't be found in systemair styles packages
.dropdown-item-container {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 37, 67, 0.2);
  border: 1px solid #d9dde1;
  border-radius: 2px;
}

// Frico specifications print out styles
$frico-main-text-color: #4c4c4c;

.frico-spec-print-default {
  font-family: Arial, Helvetica, sans-serif;
  line-height: normal;
}

.frico-spec-print-heading2 {
  font-size: 20px;
  font-weight: bold;
}

.frico-spec-print-help-container {
  width: 160px;
  padding: 0 10px;
  flex-shrink: 0;
  & > p {
    font-size: 0.9em !important;
    line-height: normal !important;
    padding-bottom: 0 !important;
    margin: 1em 0px !important;
    color: $frico-main-text-color !important;
  }
}

.frico-spec-print-table-th-text {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.frico-spec-print-max-width {
  width: 100%;
  & > p {
    line-height: normal !important;
    padding-bottom: 0 !important;
    margin: 1em 0px !important;
    color: $frico-main-text-color !important;
  }
}
.frico-spec-print-description-header {
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  & > p {
    line-height: normal !important;
    padding-bottom: 0 !important;
    margin: 1em 0px !important;
    color: $frico-main-text-color !important;
  }
}

.frico-spec-print-description-left {
  margin-top: 15px;
  & > p {
    margin: 0;
    padding: 0;
    font-size: 0.9em !important;
    line-height: normal !important;
    padding-bottom: 0 !important;
    color: $frico-main-text-color !important;
  }
}
.frico-spec-print-flex-row {
  display: flex;
  flex-direction: row;
  color: $frico-main-text-color !important;
  line-height: normal;
  & > p {
    font-size: 0.9em !important;
    line-height: normal !important;
    padding-bottom: 0 !important;
    margin: 1em 0px !important;
    color: $frico-main-text-color !important;
  }
}

.frico-spec-print-print-thead {
  font-weight: bold;
  font-size: 11px;
  margin-right: 4px;

  tr > td {
    border-bottom: 1px solid #ccc;
    padding: 1px;
  }

  & > p {
    font-size: 0.9em !important;
    line-height: normal !important;
    padding-bottom: 0 !important;
    margin: 1em 0px !important;
  }
}

.frico-spec-print-print-td {
  border-top: 1px solid #ccc;
  padding: 4px 4px 2px 8px;
  width: 33%;
  font-size: 11px;
}

.frico-spec-print-print-td-header {
  border-top: 1px solid #ccc;
  padding: 4px 4px 2px 8px;
  width: 33%;
  height: 19px;
  background-color: #edeeef !important;
  -webkit-print-color-adjust: exact;
  font-size: 11px;
  font-weight: bold;
}

.frico-spec-print-print-td-calc-result {
  border-top: 1px solid #ccc;
  font-size: 11px;
  padding: 1px;
}

.frico-spec-print-bold-p {
  font-size: 11pt;
  font-weight: bold;
}

.frico-spec-print-print-table {
  margin-top: 10px;
  font-size: 10.5pt;
  width: 100%;
  border-spacing: 0;
  color: $frico-main-text-color !important;
}

.frico-spec-print-description {
  padding: 8px 8px;
  font-size: 0.9em;
  & > h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
  }

  & > p {
    font-size: 0.9em !important;
    line-height: normal !important;
    padding-bottom: 0 !important;
    margin: 1em 0px !important;
    color: $frico-main-text-color !important;
  }
}

.frico-spec-print-foot {
  & > p {
    line-height: normal !important;
    padding-bottom: 0 !important;
    font-size: 10pt !important;
    margin: 1em 0px !important;
    color: $frico-main-text-color !important;
  }
}

// Overrides styles in the Systemair style package to be able to have themeable colors
.form-input,
.form-dropdown {
  &:hover {

    box-shadow: 0 4px 20px var(--theme-color-hover-shadow);
  
    &.form-input-small {
      box-shadow: 0 4px 12px var(--theme-color-hover-shadow);
    }
  
    &.form-input-tiny {
      box-shadow: 0 2px 10px var(--theme-color-hover-shadow);
    }  
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px var(--theme-color-active-shadow);
  }
}
